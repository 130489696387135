.hero-section {
  background: linear-gradient(135deg, #e0e0e0, #ffffff); /* Neutral gradient background */
  color: #333;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.hero-section h1 {
  font-size: 3rem; /* Larger font for emphasis */
  font-weight: 700; /* Bold text */
  color: #333; /* Dark color for contrast */
  margin: 0.5rem 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.hero-section p {
  font-size: 1.25rem; /* Slightly larger than default for readability */
  font-weight: 410; /* Regular weight for subtext */
  color: #555; /* Soft gray for contrast */
  margin: 0.25rem 0 1.5rem 0;
  max-width: 600px;
  line-height: 1.6;
}

.buy-button {
  background-color: #ffcc00;
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.buy-button:hover {
  background-color: #e6b800; /* Darken on hover */
  transform: scale(1.05); /* Slight zoom on hover */
}
